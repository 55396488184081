import Link from "next/link";
import { footerMenuList } from "../data/footer_data";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import { useRouter } from "next/router";

const Footer = () => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const fullUrl = `${process.env.NEXT_PUBLIC_PROD_DOMAIN || ""}${
    router.asPath
  }`;

  return (
    <>
      {/* <!-- Footer --> */}
      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-full sm:col-span-3 md:col-span-4 text-left sm:text-left">
              {/* <!-- Logo --> */}
              <div className="mb-3 inline-block">
                <Image
                  height={28}
                  width={130}
                  src="/images/logo.png"
                  className="max-h-7 dark:hidden"
                  alt="best airport transfers sofia, bulgaria, 24 7 cheap taxies sofia"
                  loading="lazy"
                />
              </div>
              <div className=" mb-3 inline-block">
                <Image
                  height={28}
                  width={130}
                  src="/images/logo_white.png"
                  className="hidden max-h-7 dark:block mb-6"
                  alt="sofia airport to central bus station, taxi sofia airport credit card"
                  loading="lazy"
                />
              </div>
              <p className="dark:text-jacarta-300 mb-6">{t("footer.title")}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/profile.php?id=61551206698833"
              >
                <Image
                  className="cursor-pointer"
                  src="/svgs/common/facebook.svg"
                  width={50}
                  height={50}
                  alt="Cheap airport taxi transfers Sofia, Bulgaria"
                ></Image>
              </a>
              {/* Shares to facebook */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=${fullUrl}`}
              >
                <Image
                  className="cursor-pointer"
                  src="/svgs/common/share-fb.svg"
                  width={80}
                  height={50}
                  alt="sofia airport taxi, sofia taxi transfers"
                ></Image>
              </a>
            </div>

            {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-4 ${single.diffClass}`}
                key={single.id}
              >
                <h5 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {t(single.title)}
                </h5>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <p className="hover:text-accent dark:hover:text-white">
                          {t(text)}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>
                © {new Date().getFullYear()} {t("footer.copyright")}
              </span>
            </span>

            <ul className="dark:text-jacarta-400 flex justify-center flex-wrap space-x-4 text-sm">
              <li>
                <a
                  href="https://www.raiskigaz.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hover:text-accent dark:hover:text-white">
                    raiskigaz.net
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.vapeflare.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hover:text-accent dark:hover:text-white">
                    vapeflare.net
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.n2o-bg.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hover:text-accent dark:hover:text-white">
                    n2o-bg.com
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://helliumhaven.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hover:text-accent dark:hover:text-white">
                    helliumhaven.com
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://photography-ks.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hover:text-accent dark:hover:text-white">
                    photography-ks.com
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://advokatzarinadakova.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="hover:text-accent dark:hover:text-white">
                    advokatzarinadakova.com
                  </span>
                </a>
              </li>
              
            </ul>
            <ul className="flex gap-6 text-blue">
            <li>
                <Link href="/terms">
                  <a className="hover:text-accent dark:hover:text-white">
                    {t("footer.privacy")}
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/terms">
                  <a className="hover:text-accent dark:hover:text-white">
                    {t("footer.terms")}
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
